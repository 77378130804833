import React from "react"
import { Link } from "gatsby"

export default ({ title, titleStyle, link, image, imageAlt, imageStyle, oneliner, style, children }) => {
  var plainLinkStyle = {
    textDecoration: 'none',
    color: 'black',
  }

  var cardStyle = Object.assign({
    display: 'flex',
    flex: '1 100%',
    flexFlow: 'row wrap',
    background: 'white',
    padding: '10px',
    borderTop: '3px #EEEEEE solid',
    alignItems: 'center',
  }, style)

  var imageContainerStyle = {
    flex: '1',
  }
  var imageElement = image ? (
    <div style={imageContainerStyle}>
      <img alt={imageAlt} style={imageStyle} src={image} />
    </div>
  ) : null
  imageElement = link ? <Link to={link} style={plainLinkStyle}>{imageElement}</Link> : <div>{imageElement}</div>

  titleStyle = Object.assign({
    flex: oneliner ? null : '1 100%',
    fontSize: '26px',
    fontWeight: 'bold',
  }, titleStyle)
  var titleElement = title ? (
    <Link to={link} style={plainLinkStyle}>
      <div style={titleStyle}>{title}</div>
    </Link>
  ) : null

  var contentStyle = {
    display: oneliner ? 'flex' : null,
    flex: oneliner ? '1' : '1 100%',
    flexFlow: 'row wrap',
    alignItems: oneliner ? 'center' : null,
  }
  var contentContainerStyle = {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: oneliner ? 'center' : null,
    flex: '2',
    padding: '10px',
  }

  return (
    <div style={cardStyle}>
      {imageElement}
      <div style={contentContainerStyle}>
        {titleElement}
        <div style={contentStyle}>{children}</div>
      </div>
    </div>
  )
}
